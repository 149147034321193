let userRoot = [
  {
    label: "查看工作台企业统计",
    isSelect: false,
    id: "00",
  },
  {
    label: "客户管理",
    isSelect: false,
    id: "01",
    children: [
      {
        label: "查看/编辑总客户池",
        isSelect: false,
        id: "010",
      },
      {
        label: "提供售后服务",
        isSelect: false,
        id: "011",
      },
    ],
    // label:'查看/编辑总客户池',
    // isSelect:false,
    // id:'01',
  },
  {
    label: "AI营销",
    isSelect: true,
    id: "011",
    children: [
      {
        label: "数据线索",
        isSelect: false,
        id: "0112",
      },
      {
        label: "话单记录",
        isSelect: false,
        id: "0113",
      },
      {
        label: "任务列表",
        isSelect: false,
        id: "0114",
      },
      {
        label: "AI话术",
        isSelect: false,
        id: "0115",
      },
      {
        label: "探客宝",
        isSelect: false,
        id: "0116",
      },
    ],
  },
  {
    label: "企业管理",
    isSelect: true,
    id: "02",
    children: [
      {
        label: "企业信息设置",
        isSelect: false,
        id: "020",
      },
      {
        label: "企业成员",
        isSelect: false,
        id: "021",
        children: [
          {
            label: "部门设置",
            isSelect: false,
            id: "0210",
            children: [
              {
                label: "仅可设置自己负责的部门",
                isSelect: true,
                id: "02100",
              },
              {
                label: "可设置所有部门",
                isSelect: true,
                id: "02101",
              },
            ],
          },
          {
            label: "管理员设置",
            isSelect: false,
            id: "0211",
          },
          {
            label: "成员设置",
            isSelect: false,
            id: "0212",
          },
          {
            label: "设置员工目标",
            isSelect: false,
            id: "0213",
          },
        ],
      },
      {
        label: "高级表单设置",
        isSelect: false,
        id: "022",
      },
      {
        label: "广告图管理设置",
        isSelect: false,
        id: "023",
      },
      {
        label: "成员考核",
        isSelect: false,
        id: "024",
      },
    ],
  },
  {
    label: "订单管理设置",
    isSelect: false,
    id: "03",
  },
  {
    label: "商品管理",
    isSelect: false,
    id: "04",
    children: [
      {
        label: "商品管理设置",
        isSelect: false,
        id: "040",
      },
      {
        label: "预约管理设置",
        isSelect: false,
        id: "041",
      },
    ],
  },
  {
    label: "营销活动",
    isSelect: false,
    id: "05",
    children: [
      {
        label: "分销设置",
        isSelect: false,
        id: "050",
      },
      {
        label: "拼团设置",
        isSelect: false,
        id: "051",
      },
      {
        label: "秒杀设置",
        isSelect: false,
        id: "052",
      },
      {
        label: "优惠券设置",
        isSelect: false,
        id: "053",
      },
    ],
  },
  {
    label: "转介绍管理",
    isSelect: false,
    id: "012",
    children: [
      {
        label: "合作伙伴列表",
        isSelect: false,
        id: "0120",
      },
      {
        label: "规则设置",
        isSelect: false,
        id: "0121",
      },
      {
        label: "提现列表",
        isSelect: false,
        id: "0122",
      },
    ],
  },
  {
    label: "财务管理设置",
    isSelect: false,
    id: "06",
    children: [
      {
        label: "财务管理",
        isSelect: false,
        id: "060",
      },
      {
        label: "佣金管理",
        isSelect: false,
        id: "061",
      },
    ],
  },
  {
    label: "获客文章管理设置",
    isSelect: false,
    id: "07",
    children: [
      {
        label: "获客文章管理设置",
        isSelect: false,
        id: "070",
      },
      {
        label: "员工数据设置",
        isSelect: false,
        id: "071",
      },
      {
        label: "访客数据设置",
        isSelect: false,
        id: "072",
      },
    ],
  },
  {
    label: "短视频管理",
    isSelect: false,
    id: "08",
    children: [
      {
        label: "添加/编辑/设置短视频(成员自己)",
        isSelect: false,
        id: "080",
      },
      {
        label: "编辑/设置短视频(其他成员)",
        isSelect: false,
        id: "081",
      },
    ],
  },
  {
    label: "增值服务",
    isSelect: false,
    id: "09",
    children: [
      {
        label: "需求广场审核/举报设置",
        isSelect: false,
        id: "090",
      },
      {
        label: "产品中心审核设置",
        isSelect: false,
        id: "091",
      },
      {
        label: "人脉集市审核设置",
        isSelect: false,
        id: "092",
      },
      {
        label: "发布设置",
        isSelect: false,
        id: "093",
      },
      {
        label: "自定义设置",
        isSelect: false,
        id: "094",
      },
    ],
  },
  {
    label: "分享推广管理设置",
    isSelect: false,
    id: "010",
  },
  {
    label: "小程序设置",
    isSelect: false,
    id: "011",
    children: [
      {
        label: "基础设置",
        isSelect: false,
        id: "0110",
      },
      {
        label: "商城设置",
        isSelect: false,
        id: "0111",
      },
    ],
  },
];

let userInfo = JSON.parse(localStorage.getItem("info"));
if (!userInfo.main) {
  userRoot.forEach((item, index) => {
    console.log(item);
    if (item.id == "09" || item.id == "10") {
      userRoot.splice(index, 1);
    }
  });
  userRoot.forEach((item, index) => {
    if (item.id == "010") {
      userRoot.splice(index, 1);
    }
  });
}
console.log(userRoot, "userRoot---------------------------");

export default {
  userRoot,
};
